import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Roles from '../resources/UserRole';
import ClaimService from '../services/ClaimService';
import Notifications from "../services/Notifications";
import Claims from './claims/Claims';
import NonFastTrackModal from './claims/NonFastTrackModal';
import ClaimsFilter from './claims/ClaimsFilter';
import ClaimsSubmission from './claims/ClaimsSubmission';
import Paging from './Paging';
import DateHelper from '../helpers/DateHelper';
import UserHelper from '../helpers/UserHelper';
import { withSelectedCustomer } from '../hooks/withSelectedCustomer';
import { withStorefrontConfig } from '../hooks/StorefrontSettingsContext';
import { withWarrantyConfig } from '../hooks/WarrantyConfigContext';
import { getState } from './account/AccountSummary';
import NotificationBanner from "./banner/NotificationBanner";

export const ClaimHistory = (props) => {
    const localized = props.WarrantyConfig.labels;
    const [status, setStatus] = useState("");
    const [statusCodes, setStatusCodes] = useState("");
    const [claimsStatus, setClaimsStatus] = useState("");
    const [claimStatusFilters, setClaimStatusFilters] = useState({ value: "", label: "" });

    const [fullName, setFullName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [customerNumber, setCustomerNumber] = useState("");
    const [branchPlant, setBranchPlant] = useState("");

    const [dateRange, setDateRange] = useState("30");
    const [statusDescription, setStatusDescription] = useState("");
    const [approvalStatus, setApprovalStatus] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(100);

    const [count, setCount] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [customerId, setCustomerId] = useState(props && props.selectedCustomer ? props.selectedCustomer.id : null);
    const [billToCustomer, setBillToCustomer] = useState(props && props.selectedCustomer ? props.selectedCustomer.billToId : null);
    const [storefrontId, setStorefrontId] = useState(props && props.currentUser.storefrontId);

    // Pending Claims added from form
    const [claims, setClaims] = useState(null);
    const [addingItem, setAddingItem] = useState(false);

    // State Passed To ClaimsSubmission Start
    const [claimLines, setClaimLines] = useState([]);
    const [returnPolicy, setReturnPolicy] = useState(false);
    const [obsolete, setObsolete] = useState(false);
    const [duplicateSerial, setDuplicateSerial] = useState(false);
    const [isPioneerItem, setIsPioneerItem] = useState(false);
    const [pioneerBrand, setPioneerBrand] = useState({});
    const [itemDispositions, setItemDispositions] = useState([]);
    const [productType, setProductType] = useState("");

    // Validation
    const [isValidClaim, setIsValidClaim] = useState(false);
    const [claimApproval, setClaimApproval] = useState([]);

    // Errors
    const [commentsError, setCommentsError] = useState(false);
    const [serialNumberErrors, setSerialNumberErrors] = useState(false);
    const [claimTypeOptionError, setClaimTypeOptionError] = useState(false);
    const [referenceNumberError, setReferenceNumberError] = useState(false);
    const [itemNumberError, setItemNumberError] = useState(false);
    const [installDateError, setInstallDateError] = useState(false);
    const [failureDateError, setFailureDateError] = useState(false);
    const [loyalityIDWarning, setLoyalityIDWarning] = useState(false);
    const [serialNumberWarning, setSerialNumberWarning] = useState(false);
    const [dateInstalledError, setDateInstalledError] = useState(false);
    const [fastTrackError, setFastTrackError] = useState(false);

    // Upload Files
    const [uploadFiles, setUploadFiles] = useState([]);

    // Input Form Values
    const [itemNumberSubmit, setItemNumberSubmit] = useState("");
    const [serialNumberSubmit, setserialNumberSubmit] = useState("");
    const [loyaltyIDSubmit, setLoyaltyIDSubmit] = useState("");
    const [referenceNumberSubmit, setReferenceNumberSubmit] = useState("");
    const [commentsSubmit, setCommentsSubmit] = useState("");
    const [claimType, setClaimType] = useState("");
    const [detailsCommentSubmit, setDetailsCommentSubmit] = useState("");
    const [dateFailedSubmit, setDateFailedSubmit] = useState("");
    const [dateInstalledSubmit, setDateInstalledSubmit] = useState("");
    const [pumpManufacturer, setPumpManufacturer] = useState("");
    const [failureType, setFailureType] = useState({selected: "", isCommentsRequired: false});
    // State Passed To ClaimsSubmission End

    // Edit Mode
    const [isEditMode, setIsEditMode] = useState(false);
    const [serialNumberBeingEdited, setSerialNumberBeingEdited] = useState(null);

    const [canApproveRejectClaim, setCanApproveRejectClaim] = useState(
      props.currentUser.roles.includes(Roles.CAN_APPROVE_REJECT_CLAIM)
        ? true
        : false
    );
    const [showNonFastTrackModal, setShowNonFastTrackModal] = useState(false);
    const [allowReplacement, setAllowReplacement] = useState(false);
    const [serialNumberClassName, setSerialNumberClassName] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [allDispositions, setAllDispositions] = useState([]);

    const getStates = () => {
        return {
            status, dateRange, pageSize, page, searchValue, claimsStatus, claimStatusFilters
            , statusDescription, claimType, approvalStatus, claims, count, fetching, customerId, billToCustomer, storefrontId, commentsSubmit, detailsCommentSubmit
            , itemNumberSubmit, serialNumberSubmit, dateInstalledSubmit, dateFailedSubmit, referenceNumberSubmit, loyaltyIDSubmit, itemNumberError, dateInstalledError, installDateError
            , commentsError, failureDateError, fastTrackError, claimLines, claimApproval, canApproveRejectClaim, serialNumberWarning, loyalityIDWarning, showNonFastTrackModal, referenceNumberError
            , claimTypeOptionError, allowReplacement, returnPolicy, obsolete, duplicateSerial, serialNumberClassName, serialNumberErrors, isValidClaim, pioneerBrand, isPioneerItem
            , uploadFiles, itemDispositions, allDispositions
        }
    };

    useEffect(() => {
        updateClaims();
        fetchStatusCodes();
        fetchClaimsStatus();
        fetchClaimDispositions();
    }, []);

    useEffect(() => {
        if (props?.selectedCustomer?.id) {
            setCustomerId(props?.selectedCustomer?.id);
            setBillToCustomer(props?.selectedCustomer?.billToId)
            resetChanges();
            fetchStatusCodes();
            fetchClaimDispositions();
        }
    }, [props?.selectedCustomer?.id]);

    useEffect(() => {
        updateClaims();
    }, [customerId, status, page, dateRange, searchValue]);

    useEffect(() => {
        fetchClaims();
    }, [page]);

    var hasFastTrackError = false;

    const loadDispositions = (response) => {
        if (response) {
            setAllDispositions(response);
        }
    };

    const fetchClaimDispositions = () => {
        if (props?.selectedCustomer) {
            const storefrontId_1 = storefrontId || props.currentUser.storefrontId;
            return ClaimService.fetchDispositions(storefrontId_1).then(loadDispositions);
        }
    };

    const setStatusCallback = (e) => {
        let status = null;
        status = e?.value;
        setStatus(status);
        setPage(1);
    };

    const setSearchValueCallback = (e) => {
        setSearchValue(e.target.value);
        setPage(1);
    };

    const setDateRangeCallback = (e) => {
        setDateRange(e.target.value);
        setPage(1);
    };

    const setClaimTypeCallback = (e) => {
        setClaimTypeOptionError(false);
        const selectedOption = {
            value: e.target.value,
            name: e.target.options[e.target.selectedIndex].text
        }
        setClaimType(selectedOption);
    };

    const setClaimCredit = (setCredit) => {
        if (setCredit) {
            setClaimType({ value: '1', name: 'Credit' });
        }
        else {
            setClaimType("");
        }
    };

    const setAnalysisOnlyClaimType = () => {
        setClaimType({ value: '3', name: 'Analysis Only' });
    };

    const setPioneerItem = (e) => {
        setIsPioneerItem(e);
    };

    const setItemNumberSubmitCallback = (e) => {
        setObsolete(false);
        setItemNumberSubmit(e.target.value);
    };

    const setSerialNumberSubmit = (e) => {
        setDuplicateSerial(false);
        if (UserHelper.checkSpecialCharacters(e.target.value)) {
            setSerialNumberErrors(true);
            setSerialNumberClassName("warning-textbox");
        }
        else {
            setserialNumberSubmit(e.target.value);
            setSerialNumberErrors(false);
            setSerialNumberClassName("");
        }
    };

    const setDuplicateSerialCallback = (isDuplicate) => {
        setDuplicateSerial(isDuplicate);
    };

    const setSerialNumberWarningCallback = (isWarning) => {
        setSerialNumberWarning(isWarning);
    };

    const checkAllAreDigits = (value, index) => {
        if (value.length === 0) return true;
        if (!index) index = 0;
        if (isNaN(value[index])) return false;
        if (index === value.length - 1) return true;
        return checkAllAreDigits(value, ++index);
    };

    const setLoyaltyIDSubmitCallback = (e) => {
        if (checkAllAreDigits(e.target.value)) {
            setLoyalityIDWarning(false);
            setLoyaltyIDSubmit(e.target.value);
        }
        else {
            setLoyalityIDWarning(true);
        }
    };

    const setDateInstalledSubmitCallback = (date) => {
        let dateFormat = props.StorefrontConfig.settingsList.DateFormat ? props.StorefrontConfig.settingsList.DateFormat : "MM/dd/yyyy";
        setDateInstalledSubmit(DateHelper.format(date, dateFormat));
    };

    const setDateFailedSubmitCallback = (date) => {
        let dateFormat = props.StorefrontConfig.settingsList.DateFormat ? props.StorefrontConfig.settingsList.DateFormat : "MM/dd/yyyy";
        setDateFailedSubmit(DateHelper.format(date, dateFormat));
    };

    const setReferenceNumberSubmitCallback = (e) => {
        setReferenceNumberSubmit(e.target.value);
    };

    const setCommentsSubmitCallback = (e) => {
        setCommentsSubmit(e.target.value);
    };

    const setDetailsCommentSubmitCallback = (e) => {
        setDetailsCommentSubmit(e);
    };

    const pageCount = () => {
        return Math.ceil(count / pageSize);
    };

    const nextPage = () => {
        if (page < pageCount()) {
            setPage(page + 1);
        }
    };

    const previousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const updateClaims = () => {
        fetchClaims();
    };

    const fetchClaims = () => {
        if (props.selectedCustomer) {
            setFetching(true);
            return ClaimService.fetchClaims(getStates(), props).then(loaded); // need review
        }
    };

    const fetchStatusCodes = () => {
        if (props.selectedCustomer) {
            return ClaimService.fetchStatusCodes().then(loadStatusCodes);
        }
    };

    const loadStatusCodes = (response) => {
        if (response)
            setStatusCodes(response);
    };

    // fetch warranty all status information
    const fetchClaimsStatus = () => {
        if (props.selectedCustomer) {
            const storefrontId_1 = storefrontId || props.currentUser.storefrontId;
            return ClaimService.fetchClaimsStatus(storefrontId_1).then(loadClaimsStatus);
        }
    };

    const loadClaimsStatus = (response) => {
        if (response) {
            setClaimsStatus(response.warrantyClaimStatus);
        }
    };

    const loaded = (data) => {
        if (data) {
            setFetching(false);
            if (status) {
                let filteredClaims = data.warrantyClaims.filter(o => o.statusDescription === status);
                setClaims(filteredClaims);
                setCount(filteredClaims.length);
            }
            else {
                setClaims(data.warrantyClaims);
                setCount(data.totalCount);

            }
        }
        let statusResult = [];
        const filterStatus = data.warrantyClaims.map((o) => o.statusDescription).filter((v, i, a) => a.indexOf(v) === i);
        filterStatus.forEach((o) => {
            statusResult.push({ value: o, label: o });
        });

        setClaimStatusFilters(statusResult);

    };

    const addClaimItem = async () => {
        setAddingItem(true);
        return validateSubmission().then(async (valid) => {
            if (valid) {
                saveClaimItem();
            }

            setAddingItem(false);

            return valid;
        }).then(o => {
            return o;
        });
    };

    const editClaimItem = async () => {
        try {
            if (!isEditMode) {
                return;
            }

            setAddingItem(true);
            const isValid = await validateSubmission();

            if (!isValid) {
                setAddingItem(false);

                return false;
            }

            removeClaimItem(serialNumberBeingEdited);
            saveClaimItem();
            setAddingItem(false);

            return true;
        } catch (error) {
            console.error('Error editing claim item:', error);

            return false;
        }
    };

    const clearForm = () => {
        setCommentsSubmit("");
        setDetailsCommentSubmit("");
        setItemNumberSubmit("");
        setserialNumberSubmit("");
        setDateFailedSubmit(null);
        setDateInstalledSubmit(null);
        setReferenceNumberSubmit("");
        setLoyaltyIDSubmit("");
        setFastTrackError("");
        setClaimType("");
        setObsolete(false);
        setPioneerBrand("");
        setIsPioneerItem(false);
        setReturnPolicy("");
        setUploadFiles([]);
        setFullName("");
        setEmailAddress("");
        setCustomerNumber("");
        setBranchPlant("");
        setSerialNumberBeingEdited(null);
        setIsEditMode(false);
        setPumpManufacturer("");
        setFailureType({selected: "", isCommentsRequired: false});
    };

    const validateFastTrack = (itemNumber) => {
        return ClaimService.fetchFastTrackStatus(itemNumber)
            .then(data => {
                setReturnPolicy(data.returnPolicy);
                setObsolete(data.obsolete);
                setPioneerBrand(data.pioneerBrand);
                setIsPioneerItem(data.pioneerBrand && data.pioneerBrand.shown == true ? true : false);
                setDispositions(data);
                setProductType(data.productType);

                data?.productType !== "Motor" && setPumpManufacturer("");
            });
    };

    const checkIfItemNumberIsEligibleForValidation = async (itemNumber) => {
        try {
            const response = await ClaimService.checkIfItemNeedsValidation(itemNumber);

            return response;
        } catch (error) {
            console.error("Error checking if item number needs validation:", error);

            return false;
        }
    };

    const bypassFastTrackValidation = (returnPolicyBypass, itemNumber) => {
        return ClaimService.fetchFastTrackStatus(itemNumber)
            .then(data => {
                setReturnPolicy(data.returnPolicy);
                setObsolete(data.obsolete);
                setPioneerBrand(data.pioneerBrand);
                setIsPioneerItem(data.pioneerBrand && data.pioneerBrand.shown == true ? true : false);
                data.returnPolicy = returnPolicyBypass;
                setDispositions(data);
                setProductType(data.productType);

                data?.productType !== "Motor" && setPumpManufacturer("");
            });
    };

    const setDispositions = (data) => {
        const filterDispositions = allDispositions.warrantyClaimDisposition.filter(dt => dt.returnPolicy == data.returnPolicy);
        if (data.returnPolicy == ' ' && canApproveRejectClaim === false) {
            setItemDispositions(filterDispositions.filter(al => al.id != 4));// Analysis only based on role
        }
        else {
            setItemDispositions(filterDispositions);
        }
    };

    const validateFastTrackSerialNumber = async (serialNumberUnformatted) => {
        let serialNumber = serialNumberUnformatted.replace(/[^0-9a-zA-Z]/g, '').replace(/\s/g, '');

        let monthLetters = { 'A': 1, 'B': 2, 'C': 3, 'D': 4, 'E': 5, 'F': 6, 'G': 7, 'H': 8, 'J': 9, 'K': 10, 'L': 11, 'M': 12 };
        let flag = false;

        if (serialNumber.length > 13) {
            Notifications.error(props.WarrantyConfig.labels.SerialFastTrackLengthError);
            flag = true;
        }
        else {
            if (!/^\d{2}([ABCDEFGHJKLMabcdefghjklm]){1}([A-Za-z0-9]){2}[0-3]\d.{5}[A-Za-z0-9]/.test(serialNumber)) {
                Notifications.error(props.WarrantyConfig.labels.SerialFastTrackFormatError);
                flag = true;
            }
            else {
                let serialMonth = serialNumber.charAt(2);
                let serialYear = serialNumber.substring(0, 2);
                let serialMonthValue = monthLetters[serialMonth.toUpperCase()];
                let serialDay = serialNumber.substring(5, 7);
                if (serialYear <= 0) {
                    Notifications.error(props.WarrantyConfig.labels.SerialFastTrackFormatError);
                    flag = true;
                }
                if (serialDay > 31) {
                    Notifications.error(props.WarrantyConfig.labels.SerialFastTrackFormatError);
                    flag = true;
                }
                else if (serialDay < 1) {
                    Notifications.error(props.WarrantyConfig.labels.SerialFastTrackFormatError);
                    flag = true;
                }
                else {
                    let serialDate = new Date('20' + serialYear, (serialMonthValue-1), serialDay);
                    let currentDate = new Date();
                    if (serialDate > currentDate) {
                        Notifications.error(props.WarrantyConfig.labels.SerialFutureError);
                        flag = true;
                    }

                    if (DateHelper.subtract(currentDate, { years: 5 }) > serialDate) {
                        Notifications.errorLong(props.WarrantyConfig.labels.SerialFastTrackAgeError);
                        flag = true;
                    }
                }
            }
        }
        hasFastTrackError = flag;
        setFastTrackError(flag);
    };

    const checkSerialNumber = async (itemNumber) => {
        var serialNumber = serialNumberSubmit.trim();
        const isEligibleForValidation = await checkIfItemNumberIsEligibleForValidation(itemNumber);

        //if an item is fast track, make sure the length is no greater than 13 characters
        if (returnPolicy === 'F' && isEligibleForValidation && props.WarrantyConfig.settings.ShowSerialNumberAlert) {
            await validateFastTrackSerialNumber(serialNumber);
        }

        // No duplicate serial numbers check for pioneer items. They comes in packing might have same serial numbers for multiple claims.
        if (serialNumber != "" && !isPioneerItem) {
            return await ClaimService.fetchWarrantySerialNumber(serialNumber)
                .then(data => {
                    if (data !== null) {
                        setSerialNumberClassName("unavailable");
                        setSerialNumberWarning(true);
                        Notifications.error(props.WarrantyConfig.labels.DuplicateSerialNumber);
                    }
                    else {
                        setSerialNumberClassName("");
                        setSerialNumberWarning(false);
                    }
                    return data;
                });
        } else {
            setSerialNumberErrors(serialNumber === "");
            setSerialNumberClassName("unavailable");
        }
    };

    const validateInstallFailureDate = () => {
        if (isPioneerItem) {
            return true;
        }

        const installDate = dateInstalledSubmit;
        const failureDate = dateFailedSubmit;

        if (!installDate || !failureDate) {
            return false;
        }

        const currentDate = new Date();
        const cutoffDate = DateHelper.subtract(currentDate, { months: 60 });
        const compareInstallDate = DateHelper.checkDateIsString(installDate, props.StorefrontConfig.settingsList.dateFormat);
        const compareFailureDate = DateHelper.checkDateIsString(failureDate, props.StorefrontConfig.settingsList.dateFormat);

        // Install Date can't be in the future
        if (DateHelper.compareDates(compareInstallDate, currentDate) === 1) {
            Notifications.error(localized.DateInTheFuture);
            setInstallDateError(true);

            return false;
        }

        // Failure Date can't be in the future
        if (DateHelper.compareDates(compareFailureDate, currentDate) === 1) {
            Notifications.error(localized.DateInTheFuture);
            setFailureDateError(true);

            return false;
        }

        // Install Date should be within the warranty period (cutoff date)
        if (DateHelper.compareDates(cutoffDate, compareInstallDate) === 1) {
            Notifications.error(localized.OutsideReturnPeriodError);
            setInstallDateError(true);

            return false;
        }

        // Failure Date should be on or after Install Date
        if (DateHelper.compareDates(compareInstallDate, compareFailureDate) === 1) {
            Notifications.error(localized.FailureDateBeforeInstallDate);
            setFailureDateError(true);

            return false;
        }

        setInstallDateError(false);
        setFailureDateError(false);
        return true;
    };

    const bypassDuplicateSerial = () => {
        if (!isEditMode) {
            return true;
        }

        const serialNumberIdentifier = serialNumberBeingEdited.split('-')[0];

        return !(serialNumberIdentifier === serialNumberSubmit);
    };

    const validateSubmission = async () => {
        // Valid Item Number is required
        var itemNumber = itemNumberSubmit.trim();
        var validData = true;

        // check for valid serial number
        const validSerialNumber = await checkSerialNumber(itemNumber);
        let serialNumber = serialNumberSubmit.trim();
        if (validSerialNumber != null) {
            validData = false;
        }

        if (claimLines.filter(o => o.serialNumber === serialNumber).length > 0 && bypassDuplicateSerial()) {
            setSerialNumberClassName("unavailable");
            setSerialNumberWarning(true);
            Notifications.error(props.WarrantyConfig.labels.DuplicateSerialNumber);
            validData = false;
        }

        const validDate = validateInstallFailureDate();
        if (!validDate) {
            validData = false;

            return false;
        }

        return ClaimService.fetchWarrantyItemStatus(itemNumber.trim())
            .then(data => {
                if (data.sku === "" || data.sku === null) {
                    itemNumber = "";
                    validData = false;
                    Notifications.error("Invalid Item Number Entered");
                }
                setItemNumberError(itemNumber === "");

                // reference number is required
                let referenceNumber = referenceNumberSubmit.trim();
                setReferenceNumberError(referenceNumber === "");

                //Comments are required
                let comments = commentsSubmit.trim();
                setCommentsError(comments === "");

                setClaimTypeOptionError(claimType === "");

                const validAdditonalComments = (failureType.isCommentsRequired && (detailsCommentSubmit == null || detailsCommentSubmit.trim() === ""));

                // pioneer warranty validation rules
                if (isPioneerItem === true) {
                    if (pioneerBrand && pioneerBrand.required == true) {
                        return !(
                            itemNumber === "" ||
                            comments === "" ||
                            referenceNumber === "" ||
                            serialNumber === "" ||
                            failureType.selected === "" ||
                            validAdditonalComments);
                    }
                    else {
                        return !(
                            itemNumber === "" ||
                            comments === "" ||
                            referenceNumber === "" ||
                            failureType.selected === "" ||
                            validAdditonalComments);
                    }
                }

                var isValid = !(
                    itemNumber === "" ||
                    comments === "" ||
                    claimType === "" ||
                    referenceNumber === "" ||
                    serialNumber === "" ||
                    failureType.selected === "" ||
                    validAdditonalComments) && validData;

                return isValid;
            });
    };

    const validatePioneerItem = (itemNumber, comments, referenceNumber, serialNumber) => {
        if (pioneerBrand && pioneerBrand.shown === true && pioneerBrand.required == true) {
            var isValidPioneerSnoItem = !(itemNumber === "" || comments === "" || referenceNumber === "" || serialNumber === "");
            return isValidPioneerSnoItem;
        }
        else {
            var isValidPioneerItem = !(itemNumber === "" || comments === "" || referenceNumber === "");
            return isValidPioneerItem;
        }
    };

    const removeClaimItem = (key) => {
        const updatedClaimLines = [...claimLines];
        const index = updatedClaimLines.findIndex(o => o.key === key);
        updatedClaimLines.splice(index, 1);

        // Clearing off the duplicate serial numbers validation message on claim submission.
        const validClaimLine = updatedClaimLines.filter((o) => o.isValid === false);
        if (validClaimLine?.length === 0) {
          setIsValidClaim(false);
        }

        setClaimLines(updatedClaimLines);
  };

    const loadClaimLineItemForEdit  = (key) => {
        setIsEditMode(true);
        setAddingItem(false);

        // Find the claim line to edit
        const index = claimLines.findIndex(o => o.key === key);
        const claimLine = claimLines[index];
        setSerialNumberBeingEdited(key);

        // Fill the form with the claim line data
        setCommentsSubmit(claimLine.comments);
        setDetailsCommentSubmit(claimLine.detailsComments);
        setItemNumberSubmit(claimLine.itemNumber);
        setserialNumberSubmit(claimLine.serialNumber);
        setDateInstalledSubmit(new Date(claimLine.dateInstalled));
        setDateFailedSubmit(new Date(claimLine.dateFailed));
        setReferenceNumberSubmit(claimLine.referenceNumber);
        setLoyaltyIDSubmit(claimLine.loyaltyID);
        setClaimType(claimLine.claimType);
        setObsolete(claimLine.obsolete);
        setPioneerBrand(claimLine.pioneerBrand);
        setIsPioneerItem(claimLine.isPioneerItem);
        setReturnPolicy(claimLine.returnPolicy);
        setUploadFiles([...claimLine.uploadFiles]);
        setFullName(claimLine.fullName);
        setEmailAddress(claimLine.emailAddress);
        setCustomerNumber(claimLine.customerNumber);
        setBranchPlant(claimLine.branchPlant);
        setPumpManufacturer(claimLine.pumpManufacturer);
        setFailureType({selected: claimLine.failureType, isCommentsRequired: claimLine.isCommentsRequired});
    };

    const setShowModal = showModal => {
        setShowNonFastTrackModal(showModal);
    };

    const submitClaim = () => {
        let nonFastTrackClaims = claimLines.filter(item => item.fastTrack !== true);
        if (nonFastTrackClaims.length > 0) {
            setShowModal(true);
        }
        else executeSubmit();
    };

    const executeSubmit = async (nftClaims) => {
        let fastTrackClaims = claimLines.filter(item => item.fastTrack === true);
        let nonFastTrackClaims = nftClaims ? nftClaims : claimLines.filter(item => item.fastTrack !== true);
        setFetching(true);
        setShowNonFastTrackModal(false);

        // Validate serial numbers on claim submission.
        let validatedClaimLines = await validateClaim(fastTrackClaims, nonFastTrackClaims).then(data => {
            if (data) {
                return data;
            }
        });

        // Submit claims only if all the items are valid(no duplicates)
        let valid = validatedClaimLines?.filter(o => o.isValid == false);
        if (valid?.length === 0) {
            setIsValidClaim(false);
            submitClaimToAPI([...fastTrackClaims, ...nonFastTrackClaims]);
        }
        else {
            setFetching(false);
            setIsValidClaim(true);
            setClaimLines(validatedClaimLines);
            Notifications.errorShow(localized.DuplicateClaimSubmission);
        }
    };

    const validateClaim = async (fastTrackClaims, nonFastTrackClaims) => {
        return await ClaimService.validateClaim([...fastTrackClaims, ...nonFastTrackClaims]).then(response => {
            if (response) {
                return response;
            }
        });
    };

    const claimFailureResponse = () => {
        setFetching(false);
        Notifications.errorShow(localized.ClaimSubmitError);
    };

    const claimSuccessResponse = () => {
        Notifications.message(localized.ClaimSubmitted);
        fetchClaims();
    };

    const uploadClaimAttachments = (files, claimLine) => {
        const claimsAttachmentsToUpload = claimLines.map(claim => {
            if (claim === claimLine) {
                return {
                    ...claim,
                    uploadFiles: [...files]
                }
            }
            else {
                return {
                    ...claim
                }
            }
        });
        setClaimLines(claimsAttachmentsToUpload);
    };

    const uploadClaimAttachmentsToLocalClaimItem = (files) => {
      setUploadFiles([...files]);
    };

    const submitClaimWithoutAttachmentsToAPI = (claimsWithoutAttachments) => {
        if (claimsWithoutAttachments.length > 0) {
            ClaimService.submitClaim(claimsWithoutAttachments).then(res => {
                if (res) {
                    claimSuccessResponse();
                    setClaimLines([]); //TODO handle failure scenarios
                } else {
                    claimFailureResponse();
                }
            });
        }
    };

    const submitClaimWithAttachmentsToAPI = (claimsWithAttachments) => {
        const claimsWithoutAttachments = claimLines.filter(item => item.uploadFiles?.length == 0);
        if (claimsWithAttachments.length > 0) {
            claimsWithAttachments.map(cl => {
                ClaimService.submitClaimWithAttachment(cl).then(res => {
                    if (res !== undefined) {
                        if (claimsWithoutAttachments.length == 0) { // This check because to avoid confusion to user multiple toasts for combination of claims
                            claimSuccessResponse();
                            let removeClaimLine = claimsWithAttachments.filter(o => o.serialNumber != cl.serialNumber);
                            setClaimLines(removeClaimLine);
                        }
                        // Upload attachments to azure and send to E1.
                        ClaimService.uploadAttachment(cl.uploadFiles, res["Case Number"], cl.customerNumber).then(response => {
                            if (response) {
                                Notifications.message(localized.AttachmentSubmitted);
                            }
                            else {
                                Notifications.error(localized.AttachmentError);
                            }
                        })
                    } else {
                        if (claimsWithoutAttachments.length == 0) {
                            claimFailureResponse();
                        }
                    }
                });
            });
        }
    };

    const submitClaimToAPI = (claimLines) => {
        let claimsWithAttachments = claimLines.filter(item => item.uploadFiles?.length > 0);
        let claimsWithoutAttachments = claimLines.filter(item => item.uploadFiles?.length == 0);
        submitClaimWithAttachmentsToAPI(claimsWithAttachments);
        submitClaimWithoutAttachmentsToAPI(claimsWithoutAttachments);
    };

    const handleEnter = (e) => {
        if (e.key === "Enter")
            addClaimItem();
    };

    const handleBlur = async () => {
        var productReturnPolicy = await validateFastTrack();

    };

    const setClaimApprovalCallback = (caseNumber, e) => {
        const claimsApprovalData = claims.map(claim => {
            if (claim.caseNumber === caseNumber) {
                return {
                    ...claim,
                    approvalStatus: e.target.value
                }
            }
            else {
                return {
                    ...claim
                }
            }
        });

        setClaims(claimsApprovalData);
        const filterData = claimsApprovalData.filter(o => o.approvalStatus);
        setClaimApproval(filterData);
    };

    const saveChangesClaimApproval = () => {
        const approvalClaims = claimApproval;

        if (canApproveRejectClaim && approvalClaims.length > 0) {
            setFetching(true);
            approvalClaims.map(claim => {
                const data = {
                    caseNumber: claim.caseNumber.toString(),
                    approved: claim.approvalStatus === "1" ? "true" : "false",
                    fileName: `${claim.billToCustomer}-${claim.rmA_PONumber}${claim.documentType}`,
                    customerId: customerId,
                    fullName: `${props.currentUser.firstName} ${props.currentUser.lastName}`,
                    emailAddress: props.currentUser.email
                }
                ClaimService.claimApproval(data, customerId).then(
                    res => {
                        if (res) {
                            data.approved ? Notifications.message(localized.ClaimApproved) : Notifications.message(localized.ClaimRejected);

                        } else {
                            Notifications.error(localized.ClaimApprovalError);
                        }
                        updateClaims();
                    });
            })
        }
    };

    const resetChanges = () => {
        clearForm();
        setClaimLines([]);
        setClaimApproval([]);
        if (claims?.length > 0) {
            const claimsApprovalData = claims.map(claim => {
                return {
                    ...claim,
                    approvalStatus: ''
                }
            });
            setClaims(claimsApprovalData);
        }
    };

    const saveClaimItem = () => {
        var lineNumber;
        var fastTrackValid = returnPolicy;
        if (fastTrackValid === 'F') {
            let fastTrackClaimLines = claimLines.filter(item => item.fastTrack === true && item.claimType.value === "1");// Credit
            var newLineIndex = fastTrackClaimLines.length + 1;
            lineNumber = newLineIndex.toFixed(2);
        }

        if (hasFastTrackError == false) {
            let newClaimLine = {
                comments: commentsSubmit,
                detailsComments: detailsCommentSubmit,
                itemNumber: itemNumberSubmit,
                serialNumber: serialNumberSubmit,
                dateInstalled: dateInstalledSubmit,
                dateFailed: dateFailedSubmit,
                referenceNumber: referenceNumberSubmit,
                loyaltyID: loyaltyIDSubmit,
                rmaLineNumber: lineNumber,
                fastTrack: (fastTrackValid === 'F'),
                claimType: claimType,
                fullName: props.currentUser.firstName + ' ' + props.currentUser.lastName,
                emailAddress: props.currentUser.email,
                customerNumber: customerId,
                billToCustomer: billToCustomer,
                branchPlant: props.currentUser.storefrontNumber,
                returnConfirmation: true,
                obsolete: obsolete,
                pioneerBrand: pioneerBrand,
                isPioneerItem: isPioneerItem,
                uploadFiles: uploadFiles,
                returnPolicy: returnPolicy,
                pumpManufacturer: pumpManufacturer,
                failureType: failureType.selected,
                isCommentsRequired: failureType.isCommentsRequired,
                key: serialNumberSubmit + - + new Date().getTime(),
            };

            setClaimLines((prevState) => ([...prevState, newClaimLine]));
            clearForm();
        }
    }

    const renderClaimSubmission = () => {
        return (
            <ClaimsSubmission
                setCommentsSubmit={setCommentsSubmitCallback}
                setDetailsCommentSubmit={setDetailsCommentSubmit}
                detailsCommentSubmit={detailsCommentSubmit}
                setItemNumberSubmit={setItemNumberSubmitCallback}
                setSerialNumberSubmit={setSerialNumberSubmit}
                setDateInstalledSubmit={setDateInstalledSubmitCallback}
                setDateFailedSubmit={setDateFailedSubmitCallback}
                setReferenceNumberSubmit={setReferenceNumberSubmitCallback}
                setLoyaltyIDSubmit={setLoyaltyIDSubmitCallback}
                setSerialNumberWarning={setSerialNumberWarningCallback}
                setClaimCredit={setClaimCredit}
                setDuplicateSerial={setDuplicateSerialCallback}
                commentsSubmit={commentsSubmit}
                itemNumberSubmit={itemNumberSubmit}
                serialNumberSubmit={serialNumberSubmit}
                dateInstalledSubmit={dateInstalledSubmit}
                dateFailedSubmit={dateFailedSubmit}
                referenceNumberSubmit={referenceNumberSubmit}
                loyaltyIDSubmit={loyaltyIDSubmit}
                removeClaimItem={removeClaimItem}
                uploadFiles={uploadFiles}
                uploadClaimAttachments={uploadClaimAttachments}
                uploadClaimAttachmentsToLocalClaimItem={
                    uploadClaimAttachmentsToLocalClaimItem
                }
                addClaimItem={addClaimItem}
                addingItem={addingItem}
                validateFastTrack={validateFastTrack}
                checkIfItemNumberIsEligibleForValidation={checkIfItemNumberIsEligibleForValidation}
                bypassFastTrackValidation={bypassFastTrackValidation}
                submitClaim={submitClaim}
                claimLines={claimLines}
                handleEnter={handleEnter}
                dateFormat={props.StorefrontConfig.settingsList.dateFormat}
                itemNumberError={itemNumberError}
                commentsError={commentsError}
                installDateError={installDateError}
                failureDateError={failureDateError}
                serialNumberWarning={serialNumberWarning}
                loyalityIDWarning={loyalityIDWarning}
                referenceNumberError={referenceNumberError}
                setClaimType={setClaimTypeCallback}
                claimType={claimType}
                claimTypeOptionError={claimTypeOptionError}
                serialNumberClassName={serialNumberClassName}
                handleBlur={handleBlur}
                returnPolicy={returnPolicy}
                obsolete={obsolete}
                duplicateSerial={duplicateSerial}
                claims={claims}
                serialNumberErrors={serialNumberErrors}
                fetching={fetching}
                canApproveRejectClaim={canApproveRejectClaim}
                isValidClaim={isValidClaim}
                pioneerBrand={pioneerBrand}
                isPioneerItem={isPioneerItem}
                setAnalysisOnlyClaimType={setAnalysisOnlyClaimType}
                setPioneerItem={setPioneerItem}
                itemDispositions={itemDispositions}
                locale={props.StorefrontConfig.locale}
                loadClaimLineItemForEdit={loadClaimLineItemForEdit}
                isEditMode={isEditMode}
                editClaimItem={editClaimItem}
                clearForm={clearForm}
                productType={productType}
                pumpManufacturer={pumpManufacturer}
                setPumpManufacturer={setPumpManufacturer}
                failureType={failureType}
                setFailureType={setFailureType}
            />);
    };

    const renderClaimFilter = () => {
        return (
            <ClaimsFilter
                dateRange={dateRange}
                statusFilter={status}
                setDateRange={setDateRangeCallback}
                setStatus={setStatusCallback}
                fetchClaims={updateClaims}
                claims={claims}
                canApproveRejectClaim={canApproveRejectClaim}
                saveChangesClaimApproval={saveChangesClaimApproval}
                resetChanges={resetChanges}
                claimStatusFilters={claimStatusFilters}
                searchValue={searchValue}
                setSearchValue={setSearchValueCallback}
            />);
    };

    const renderClaims = () => {
        const hasSubmit = props.currentUser.roles.includes(Roles.SUBMIT_CLAIM);
        const hasShipToOrBothAddressType = UserHelper.checkCustomerAddressType(props.selectedCustomer.addressType);
        return (
            <div>
                {
                    hasSubmit && hasShipToOrBothAddressType && renderClaimSubmission()
                }
                {
                    <div>
                        {!hasShipToOrBothAddressType && (
                            <NotificationBanner
                                classOverride="mb-4"
                                page="ClaimHistory"
                                locale={props.StorefrontConfig.locale}
                            />
                        )}

                        {renderClaimFilter()}
                    </div>
                }

                <Claims
                    claims={claims}
                    fetching={fetching}
                    renderPaging={renderPaging}
                    approvalStatus={approvalStatus}
                    setClaimApproval={setClaimApprovalCallback}
                    statusCodes={statusCodes}
                    claimsStatus={claimsStatus}
                    roles={props.currentUser.roles}
                />
            </div>
        );
    };

    const renderPaging = () => {
        return (
            <Paging activePage={page} pageCount={pageCount} nextPage={nextPage} previousPage={previousPage} />
        );
    };

    const { selectedCustomer } = props;
    return (
        <React.Fragment>
            {showNonFastTrackModal &&
                <NonFastTrackModal setShowModal={setShowModal} claims={claimLines} addNonFastTrackFields={executeSubmit} ></NonFastTrackModal>
            }
            {selectedCustomer &&
                <React.Fragment>
                    {
                        renderClaims()
                    }
                </React.Fragment>
            }
        </React.Fragment>
    );

};

ClaimHistory.propTypes = {
    selectedCustomer: PropTypes.object,
    currentUser: PropTypes.object,
    dateFormat: PropTypes.string
}
function mapStateToProps(state) {
    return {
        selectedCustomer: state.user.selectedCustomer,
        currentUser: state.user.currentUser,
    };
}
export default connect(
    mapStateToProps,
    null
)(withWarrantyConfig(withStorefrontConfig(withSelectedCustomer(ClaimHistory))));